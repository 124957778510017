import React, { useEffect } from "react";
import { Translate } from "utils/Translate";
import { Typography, Select, MenuItem } from "@material-ui/core";
import Routes from "components/Router/Routes";
import Config from "assets/config.json";
import { useSiteConfig } from "../../hooks/useSiteConfig";
const config = require("assets/config.json");
// 3rd Dec,2019 by Nikhil
// Expected latest Design
// Commented the code for adding these items to the footer
function Footer(props) {
  const { useSiteConfigState } = useSiteConfig()
  const { socialLinks, appBadgesLinks, customLinks } = useSiteConfigState()
  const {
    language,
    copyright,
    supportedLanguages = [],
    langauageSelector = false,
  } = Config;
  const version = localStorage.getItem("version");
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (langauageSelector) {
      const selectedLanguage = localStorage.getItem("language");
      if (!supportedLanguages.includes(selectedLanguage)) {
        localStorage.setItem("language", language);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const languageValues = supportedLanguages.map((items) => {
    const id = "language." + items;
    return Translate({ id, defaultMessage: items, values: items });
  });
  const handleChange = (event) => {
    localStorage.setItem("language", event.target.value);
    window.location.reload();
  };

  const copyrightValue = () => {
    const currentYear = new Date().getFullYear();
    return copyright ?? `© Wexer ${currentYear}`; //fallback in case api doesn't pass value
  };
  return (
    <footer>
      <div className="Footer">
        <div className="d-flex align-item-start ">

          <div className="d-flex container-links ">
            <div className="container-1">
              <Typography variant="h3">
                {Translate({ id: "footer.Content" })}
              </Typography>
              {(config.showChannels === true ||
                config.showChannels === undefined) && (
                  <Typography variant="h3">
                    <a href="/channels" className="footer-h3">
                      {Translate({ id: "homePage.ChannelsTitle" })}
                    </a>
                  </Typography>
                )}
              <Typography variant="h3">
                <a href={Routes.collections} className="footer-h3">
                  {Translate({ id: "homePage.CollectionsTitle" })}
                </a>
              </Typography>
              {(config.showBrowseClasses === true ||
                config.showBrowseClasses === undefined) && (
                  <Typography variant="h3">
                    <a href={Routes.searchRoute} className="footer-h3">
                      {Translate({ id: "ClassListViews.Search" })}
                    </a>
                  </Typography>
                )}
            </div>
            <div className="container-2">
              <Typography variant="h3">
                {Translate({ id: "footer.Support" })}
              </Typography>
              <Typography variant="h3">
                <a href="/contact" className="footer-h3">
                  {Translate({ id: "footer.contactUs" })}
                </a>
              </Typography>
              <Typography variant="h3">
                <a href="/tc" className="footer-h3">
                  {Translate({ id: "footer.TermsAndConditions" })}
                </a>
              </Typography>
            </div>
                   
            {customLinks && customLinks?.length !== 0 ? <div className="container-1">
              <Typography variant="h3">
                {Translate({ id: "Player.More" })}
              </Typography>
                {customLinks?.map((customLink) => {
                  if (customLink.status) {
                    const linkHoverText = customLink.linkHoverText?.localTexts.find(obj => obj["ietfTag"] === language)?.text ?? customLink.linkHoverText?.invariantText
                    const linktext = customLink.linkText?.localTexts.find(obj => obj["ietfTag"] === language)?.text ?? customLink.linkText?.invariantText;
                    return <Typography variant="h3">
                    <a target="_blank" href={customLink?.httpLink} title={linkHoverText} className="footer-h3" >
                      {linktext}
                    </a>
                    </Typography>
                  }
                }
                )}
            </div> : ""}
          </div>

          <div className="container-links-social">
            {socialLinks?.map((socialLink) => {
              if (socialLink.status) {
                return <a target="_blank" href={socialLink?.httpLink} >
                  <img src={socialLink.imageURL} />
                </a>
              }
            }
            )}
          </div>

          <div className="container-languageselector-appbadges">
            <div className="languageselector-appbadges">
              {langauageSelector && supportedLanguages.length && (
                <>
                  <Typography variant="h3" className="language-switcher">
                    {Translate({
                      id: "labelforlanguageswitcher.SiteLanguageLabel",
                    })}
                    <br />
                    <Select
                      labelId="langauage-selector-label"
                      id="langauage-selector"
                      open={open}
                      onClose={() => setOpen(false)}
                      onOpen={() => setOpen(true)}
                      value={localStorage.getItem("language")}
                      onChange={handleChange}
                    >
                      {supportedLanguages.map((suportedLanguage, index) => (
                        <MenuItem
                          className="langauage-switcher"
                          value={suportedLanguage}
                        >
                          {languageValues[index] ?? suportedLanguage}
                        </MenuItem>
                      ))}
                    </Select>
                  </Typography>
                </>
              )}
            </div>
            <div className="languageselector-appbadges container-appbadges">
              {appBadgesLinks?.map((appBadgesLink) => {
                return appBadgesLink.status ?
                  <a target="_blank" href={appBadgesLink?.httpLink} >
                    <img src={appBadgesLink.imageURL} />
                  </a> : null
              }
              )}
            </div>
          </div>

        </div>
      </div>

      <div className="d-flex justify-content-spacebtw footer-lower">
        <p className="textLeft-xs">
          {copyrightValue()}&nbsp;{Translate({ id: "footer.Copyright" })}
        </p>
        <p className="textLeft-xs text-right">
          {Translate({ id: "footer.Build" })}
          {": "}
          {version}
        </p>
      </div>
    </footer>
  );
}

export default Footer;
